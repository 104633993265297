import { ref } from '@vue/composition-api';
export function useHeader() {
  const menu = ref(false);
  const contact = ref(false);
  const toggleMenu = () => {
    menu.value = !menu.value;   
  };
  const toggleContact = () => {
    contact.value = !contact.value;   
  };
  return {
    toggleMenu,
    menu,
    contact,
    toggleContact,
  };
}
