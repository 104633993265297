








































































import axios from 'axios';
import {
  ref,
  defineComponent,
  reactive,
  onMounted,
} from '@vue/composition-api';
import { vRequired, vEmail, vPhoneRules } from 'monkey-validates-form';
export default defineComponent({
  props: {
    data: Object,
    i: Boolean,
  },
  setup(props, { emit }) {
    const valid = ref(false);
    const formContacto = ref(null);
    const formdata = new FormData();
    const date = props.data;
    const icon = props.i;
    const state: any = reactive({
      form: 'contacto',
      company: '',
      position: '',
      name: '',
      phone: '',
      email: '',
      city: '',
      message: '',
    });
    const respuesta = reactive({
      texto: '',
      type: 'error',
      visible: false,
      spinner: false,
    });
    const enviar = (e: Event) => {
      e.preventDefault();
      // const data = [
      //   { name: 'Nueva conversión en', value: state.metapage },
      //   { name: 'nombre', value: state.name },
      //   { name: 'numero', value: state.phone },
      //   { name: 'email', value: state.email },
      //   { name: 'position', value: state.position },
      //   { name: 'area', value: state.area },
      //   { name: 'rol', value: state.rol },
      //   { name: 'section', value: state.section },
      //   { name: 'country', value: state.country },
      //   { name: 'message', value: state.message },
      //   { name: 'token_rdstation', value: 'a892cee9d4ec74a1f31575a16fe6ff91' },
      //   { name: 'identificador', value: state.from },
      // ];
      // (window as any).RdIntegration.post(data);
      for (const key in state) {
        formdata.append(key, state[key]);
      }
      if ((formContacto.value as any).validate()) {
        respuesta.spinner = true;
        const formdata = new FormData();
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        for (const key in state) {
          formdata.append(key, state[key]);
        }
        axios
          .post(
            '/assets/php/sendemail.php',
            formdata,
            config,
          )
          .then((response) => {
            (formContacto.value as any).reset();
            respuesta.texto = response.data.mensaje;
            respuesta.type = 'success';
          })
          .catch((error) => {
            respuesta.texto = 'Error al enviar el mensaje';
            respuesta.type = 'error';
          })
          .finally(() => {
            respuesta.visible = true;
            respuesta.spinner = false;
          });
      }
    };
    // const extens = () => {
    //   if (
    //     state.email.includes('gmail') ||
    //     state.email.includes('hotmail') ||
    //     state.email.includes('yahoo') ||
    //     state.email.includes('outlook')
    //   ) {
    //     return 'Por favor completa con tu correo corporativo';
    //   } else {
    //     return true;
    //   }
    // };
    // onMounted(() => {
    //   console.log(page.value);
    // });
    return {
      vRequired,
      vEmail,
      vPhoneRules,
      formContacto,
      state,
      respuesta,
      enviar,
      valid,
      date,
      icon,
    };
  },
});
